import React from 'react';

const Pricing = () => {
    return (
        <div>
        <h1>Pricing Page</h1>
        <h2>click here to pay him some delicious dollars</h2>
        </div>
    )
}

export default Pricing;